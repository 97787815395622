<template>
  <div>
    <NavigationDrawer
      :miniVariant="navDrawerMini"
      :drawer="drawer"
      @collapseDrawer="collapseDrawer"
    ></NavigationDrawer>
    <Header
      :class="{ hidden: isHeaderHidden }"
      class="header"
      @collapseDrawer="collapseDrawer"
    ></Header>
    <v-main style="min-height: 100vh; background-color: #f9f9f9">
      <div style="height: 100%">
        <transition name="fade-in-up">
          <router-view />
        </transition>
        <div
          v-if="isSideCartVisible"
          class="overlay"
          @click="toggleSideCart(false)"
        ></div>
        <transition name="slide-left">
          <SideCart
            v-if="isSideCartVisible"
            :cartItems="cartItems"
            @toggleSideCart="toggleSideCart"
            @removeCartItem="removeCartItem"
            @openBasket="$router.push({ name: 'product description' })"
            @goToProduct="
              (id) =>
                $router
                  .push({ name: 'product item', params: { id: id } })
                  .catch(() => {})
            "
          />
        </transition>
      </div>
      <!-- <v-footer
            v-if="$route.meta.hideFooter ? !$route.meta.hideFooter : true"
            class="
                footer
                bg-white
                py-4
                d-flex
                grey--text
                text--darken-1
                position-absolute
              "
            id="kt_footer"
            style="font-size: 16px; left: 0; right: 0; bottom: 0"
          >
            <strong
              >Copyright &copy; 2021-2021 <a href="">TumarCrm</a>.
            </strong>
            <span>All rights reserved.</span>
            <v-spacer></v-spacer>
            <div class="float-right d-none d-sm-inline-block">
              <b>Version</b> 1.0.0
            </div>
          </v-footer> -->
    </v-main>
    <div
      :class="chatbotOpen ? 'chatbot active' : 'chatbot'"
      @click="chatbotOpen === true"
    >
      <ChatBot
        v-if="chatbotOpenMobile || !screenWidth < 980"
        @closeChatbot="chatbotOpenMobile = false"
        @showChatbot="showChatbot"
        :style="screenWidth < 980 && !chatbotOpenMobile && { display: 'none' }"
      />
      <v-icon
        class="chatbotIcon"
        v-if="!chatbotOpenMobile && screenWidth < 980"
        @click="chatbotOpenMobile = !chatbotOpenMobile"
        >mdi-message-text</v-icon
      >
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import NavigationDrawer from '@/view/layout/navigation/NavigationDrawer.vue'
import Header from '@/view/layout/header/Header.vue'
import SideCart from '@/view/layout/sidecart/admin/SideCartAdmin.vue'
import ChatBot from '@/view/content/chatbot/'
const { mapState: State_cart, mapActions: Action_cart } =
  createNamespacedHelpers('cart')
const {
  mapState: State_auth,
  mapActions: Actions_auth,
  mapGetters: Getters_auth,
} = createNamespacedHelpers('auth')
const { mapActions: Actions_priceList } = createNamespacedHelpers('priceList')

export default {
  name: 'Layout',
  components: {
    NavigationDrawer,
    Header,
    SideCart,
    ChatBot,
  },
  data() {
    return {
      navDrawerMini: false,
      drawer: true,
      first_name: '',
      last_name: '',
      password: '',
      chatbotOpen: false,
      chatbotOpenMobile: false,
      screenWidth: window.innerWidth,
    }
  },
  computed: {
    ...State_auth(['user']),
    ...Getters_auth(['isAuthenticated']),
    ...State_cart(['cartItems', 'isSideCartVisible']),
  },
  watch: {
    '$route.fullPath'(val) {
      this.$vuetify.goTo(0, { duration: 0 })
      this.toggleSideCart(false)
    },
    '$vuetify.breakpoint.smAndDown': {
      immediate: true,
      handler(val) {
        this.drawer = !val
      },
    },
    isSideCartVisible(val) {
      if (val) {
        document.documentElement.style.overflow = 'hidden'
      } else {
        document.documentElement.style.overflow = 'auto'
      }
    },
  },
  methods: {
    ...Action_cart(['toggleSideCart', 'removeCartItem']),
    ...Actions_auth(['fetchProfile', 'updateProfile']),
    ...Actions_priceList(['fetchPriceList']),
    collapseDrawer() {
      if (this.$vuetify.breakpoint.mdAndUp) {
        this.navDrawerMini = !this.navDrawerMini
        return
      }
      this.drawer = !this.drawer
    },
    showChatbot(show) {
      this.chatbotOpen = show
    },
    handleResize() {
      this.screenWidth = window.innerWidth
    },
    handleScroll() {
      let st = window.pageYOffset || document.documentElement.scrollTop
      if (st > this.lastScrollTop) {
        // Прокрутка вниз
        this.isHeaderHidden = true
      } else {
        // Прокрутка вверх
        this.isHeaderHidden = false
      }
      this.lastScrollTop = st <= 0 ? 0 : st // Для обработки ситуации скролла вверх до самого верха страницы
    },
  },
  created() {
    this.fetchProfile()
    this.fetchPriceList(1)
  },
  mounted() {
    window.addEventListener('resize', this.handleResize)
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
    window.removeEventListener('scroll', this.handleScroll)
  },
}
</script>
<style lang="scss" scoped>
.overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 6;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transition: top 0.3s; /* Анимация изменения top при изменении состояния */
}

.hidden {
  top: -600px; /* Полностью скрываем заголовок, вы можете изменить это значение на высоту вашего заголовка */
}

.slide-left-enter-active,
.slide-left-leave-active {
  transition: all 0.3s ease;
}
.slide-left-leave-to,
.slide-left-enter {
  transform: translateX(100%);
}
.chatbot {
  position: fixed;
  bottom: 70px;
  right: -250px;
  z-index: 99;
  transition: right 0.5s ease;
}
.chatbot.active {
  right: 20px;
}
.chatbotIcon {
  position: fixed;
  bottom: 0px;
  right: 0px;
  z-index: 99;
  background-color: #fff;
  padding: 10px;
}
@media (max-width: 979px) {
  .chatbot {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    transition: right 0.5s ease;
  }
  .chatbot.active {
    right: 0px;
  }
}
</style>
