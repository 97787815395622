<template>
  <div class="d-flex justify-space-between align-center pa-4 side-cart__header">
    <h3 class="product-ttl">{{ $t('CART_FOOTER.basket') }}</h3>
    <v-btn
      @click="$emit('toggleSideCart', false)"
      :ripple="false"
      class="pa-0"
      text
    >
      <span>{{ $t('CART_FOOTER.close') }}</span>
      <v-icon>mdi-close</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {}
</script>

<style></style>
