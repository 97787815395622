<template>
  <div class="message d-flex align-end" :class="{ 'align-self-end': msg.me }">
    <v-avatar v-show="!msg.me" size="30" class="mr-2">
      <v-img
        src="/img/profile-photo_placeholder.jpeg"
        alt="profile image"
      ></v-img>
    </v-avatar>
    <div class="d-flex flex-column">
      <span clas="message__author" :class="{ 'align-self-end': msg.me }">
        {{ msg.author }}
      </span>
      <div class="message__body pa-3">
        <slot name="message_body">
          <div v-html="msg.body"></div>
        </slot>
        <slot name="message_actions"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChatBotMessage',
  props: ['msg'],
}
</script>

<style lang="scss" scoped>
.message {
  font-size: 14px;
}
.message__body {
  border-radius: 10px;
  background-color: #f0f0f0;
  max-width: 200px;
}
</style>
<style lang="scss">
.message {
  img {
    width: 100%;
  }
}
</style>
